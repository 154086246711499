import React, {useState} from 'react';
import Recaptcha from "react-recaptcha";
import {StaticImage} from 'gatsby-plugin-image';
import Button from '../Button';
import Section, {Title} from '../Section';
import Logo from '../../assets/svgs/logo-w.svg'
import {P} from '../Typography';


const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: ""
  });
  const [token, setToken] = useState("");
  const [isSending, setSending] = useState(false);
  const [isSent, setSent] = useState(false);
  const [isError, setError] = useState(false);

  const verify = (rsp) => {
    setToken(rsp);
  }

  const change = evt => {
    setForm({...form, [evt.target.name]: evt.target.value});
  }

  const send = () => {
    setSending(true);

    const body = JSON.stringify({ ...form, token});
    fetch("/send.php", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body
    })
      .then(rsp => rsp.json())
      .then(data => {
        setSent(true);
        setError(!data.success);
      })
      .catch(error => {
        console.log(error);
        setSent(true);
        setError(true);
      });
  };

  const isValid = form.name.trim() && form.email.trim() && token.trim();

  return (
    <div className="grid">
      <StaticImage
        src="../../assets/images/contact.jpg"
        alt="The bow valley"
        layout="fullWidth"
        objectPosition="top"
        className="row-start-1 col-start-1 object-cover"/>
      <div className="grid relative row-start-1 col-start-1 place-items-center bg-black bg-opacity-80">
        <div className="w-full h-full flex flex-col justify-center items-center">
          <Section name="contact">
            <form>
            <div className="flex flex-wrap items-center">
              <div className="w-full sm:w-1/2">
                <Title title="Contact" superTitle="Get in Touch" color="text-white" className="text-white">
                  <P className="font-normal">
                    Have a question about some of our work, or a project you think we can help out on? We'd love to hear from you.
                  </P>
                </Title>
                <div className="mt-4">
                  <label className="font-thin text-sm text-white" >
                    Name
                  </label>
                  <input type="text" name="name" value={form.name} onChange={change}
                         className="w-full border border-gray focus-visible:outline focus-visible:outline-primary rounded-sm px-4 py-3"/>
                </div>
                <div className="mt-4">
                  <label className="font-thin text-sm text-white" >
                    Email Address
                  </label>
                  <input type="text" name="email" value={form.email} onChange={change}
                         className="w-full border border-gray focus-visible:outline focus:outline-primary rounded-sm px-4 py-3"/>
                </div>
                <div className="my-4">
                  <label className="font-thin text-sm text-white" >
                    Message
                  </label>
                  <textarea rows="10" name="message" value={form.message} onChange={change}
                            className="w-full border border-gray focus-visible:outline focus:outline-primary rounded-sm px-4 py-3"/>
                </div>
                {isSent && !isError && <P className="text-white">Thanks! We will be in touch soon.</P>}
                {isSent && isError && <P className="text-lg text-white">Oops, something went wrong. Feel free to email us at <a href="mailto:info@cycling-101.com">info@cycling-101.com</a></P>}
                {!isSent && <Button color='white' disabled={!isValid || isSending} onClick={(!isValid||isSending)?null:send}>{isSending?"Working..." : "Send"}</Button>}
                <div className="mt-4">
                  <Recaptcha
                    sitekey="6LcDN6EjAAAAANbdRwFvRuihZ5XFAF6e0Q4hG1Cj"
                    verifyCallback={verify}
                  />
                </div>
              </div>
              <div className="w-full sm:w-1/2 p-16">
                <Logo/>
              </div>
            </div>
            </form>
          </Section>
        </div>
      </div>
    </div>
  )
}

export default Contact;
